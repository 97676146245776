.block {
    background-color: #5daed7;
    /* HNY: background-color: #b30000; */
    color: white;
    width: -webkit-fill-available;
    padding: 10px 48px;
    margin: 15px -48px;
}

.qr {
    height: 100px;
}